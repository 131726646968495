import React from 'react'

export interface ProductListProps {
  children?: React.ReactNode
  attributes?: React.Attributes
}

const ProductList = ({ children, ...attributes }: ProductListProps) => {
  return (
    <div data-testid="ProductList" {...attributes}>
      {children}
    </div>
  )
}

export default ProductList
