import classnames from 'classnames'
import styles from './ProductListRemoveButton.module.css'
import TrashBin from 'assets/icons/trash-bin.svg'
import Check from 'assets/icons/check.svg'

const ProductListRemoveButton = ({
  isEditing,
  ...attributes
}: {
  isEditing: boolean
  [attributes: string]: unknown
}) => (
  <button
    aria-label={
      isEditing
        ? 'Ta bort program klar med redigering'
        : 'Ta bort program öppna redigeringsläge'
    }
    className={classnames(styles.removeCardsBtn, {
      [`${styles.removeCardsBtnActive}`]: isEditing,
    })}
    type="button"
    {...attributes}
  >
    {isEditing ? <Check /> : <TrashBin />}
    {isEditing ? 'Klar' : 'Ta bort program'}
  </button>
)

export default ProductListRemoveButton
