import styles from './ProductListButtonContainer.module.css'

const ProductListButtonContainer = ({
  children,
}: {
  children?: React.ReactNode
}) => {
  return <div className={styles.buttonGroup}>{children}</div>
}

export default ProductListButtonContainer
