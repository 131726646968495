export enum EdithBlockLayout {
  FMG = 'carousel',
  LIST = 'list',
  SINGLE = 'single',
  TEXT = 'text',
  LINK = 'link',
  NEWS = 'news',
  NEWS_LIST = 'news_list',
}

export enum EdithBlockType {
  MANUAL = 'manual',
  SEARCH = 'search',
  CATEGORIES = 'categories',
  CONTINUE_PLAYING = 'continue_playing',
  RECOMMENDATIONS = 'recommendations',
}

export enum ProductType {
  SERIES = 'series',
  PROGRAM = 'program',
  EMPTY = '',
}
