import PlaceholderCard from 'components/ResultsList/PlaceholderCard/PlaceholderCard'
import styles from './Skeleton.module.css'

const Skeleton = () => {
  return (
    <div className={styles.skeletonWrapper}>
      <PlaceholderCard />
      <PlaceholderCard />
      <PlaceholderCard />
      <PlaceholderCard />
      <PlaceholderCard />
    </div>
  )
}

export default Skeleton
