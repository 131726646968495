import Card from 'components/Card/Card'
import { ContinuePlayingProgram } from 'components/ContinuePlaying/ContinuePlaying'
import { BlockTracking } from 'frontend/types'
import { LinkProduct, Product } from 'frontend/types'
import { blockItemClick } from 'frontend/utils/analytics/dataLayerDispatchers'
import React from 'react'
import styles from './ProductListCards.module.css'
import { EdithCardLayout } from 'backend/services/v1/edithService.types'
import classnames from 'classnames'
import TrashBin from 'assets/icons/trash-bin.svg'
import { useRouter } from 'next/router'

interface Props {
  products: (Product | LinkProduct | ContinuePlayingProgram)[]
  className?: string
  attributes?: React.Attributes
  removeCardDraft?: Array<number>
  setRemoveCardDraft?: React.Dispatch<React.SetStateAction<number[]>> | null
  isEditing?: boolean
  blockTracking: BlockTracking
  cardLayout: EdithCardLayout
  headingLevel: 2 | 3 | 4
  showAllCards?: boolean
}

const RemoveDraft = ({ title }: { title: string }) => (
  <div role="status" className={styles.removedDescription}>
    {title} har tagits bort ur listan
  </div>
)

const ProductListCards = ({
  products,
  removeCardDraft = [],
  isEditing,
  setRemoveCardDraft = null,
  className,
  blockTracking,
  cardLayout,
  headingLevel,
  showAllCards,
  ...attributes
}: Props) => {
  const router = useRouter()

  const childPageRegex = /^\/barn/

  const isOnChildPage = childPageRegex.test(router.asPath)

  const isRemoveCardDraft = (product: Product) => {
    return 'id' in product && removeCardDraft.includes(product.id)
  }

  const handleRemove = (id: number) => {
    if (setRemoveCardDraft) {
      setRemoveCardDraft(list => [...list, id])
    }
  }

  const RemoveButton = ({ product }: { product: Product }) => (
    <button
      onClick={() => {
        if ('id' in product && handleRemove) handleRemove(product.id)
      }}
      type="button"
      aria-label={`Ta bort programmet ${product.title}`}
    >
      Ta bort <TrashBin className={styles.trashBinIcon} aria-hidden />
    </button>
  )

  const UndoButton = ({ product }: { product: Product }) => (
    <div className={styles.undoButtonContainer}>
      <button
        type="button"
        className={styles.undoButton}
        onClick={() => {
          if (setRemoveCardDraft) {
            setRemoveCardDraft(list =>
              list.filter(id => 'id' in product && id !== product.id),
            )
          }
        }}
      >
        Ångra
      </button>
    </div>
  )

  return (
    <div
      className={classnames(styles.listContainer, {
        [`${styles.portraitCard}`]: cardLayout === EdithCardLayout.PORTRAIT,
        [`${styles.showAll}`]: showAllCards,
      })}
    >
      {products.map((product, index) => (
        <Card
          key={index}
          className={className}
          isGridCard
          cardLayout={cardLayout}
          product={product}
          withAnchor={!isEditing}
          isEditing={isEditing}
          onClick={() =>
            blockItemClick({
              blockTracking,
              product: product,
              itemIndex: index + 1,
            })
          }
          {...attributes}
        >
          <Card.Image
            product={product}
            cardLayout={cardLayout}
            blockIndex={blockTracking.blockIndex}
            isOnChildPage={isOnChildPage}
          >
            {isEditing && (
              <div className={styles.removeCardOverlay}>
                {isRemoveCardDraft(product) ? (
                  <RemoveDraft title={product.title} />
                ) : (
                  <RemoveButton product={product} />
                )}
              </div>
            )}
          </Card.Image>
          <Card.Metadata
            hideCardMetaData={isOnChildPage}
            isRemoveCardDraft={isRemoveCardDraft(product)}
            headingLevel={headingLevel}
            product={product}
            productType={'productType' in product ? product.productType : null}
            withAnchor={!isEditing}
            cardLayout={cardLayout}
          />

          {isRemoveCardDraft(product) && <UndoButton product={product} />}
        </Card>
      ))}
    </div>
  )
}

export default ProductListCards
