import { EdithCardLayout } from 'backend/services/v1/edithService.types'
import ProductListButtonContainer from 'components/ProductList/Buttons/ProductListButtonContainer'
import ProductListToggleButton from 'components/ProductList/Buttons/ProductListToggleButton'
import Header from 'components/ProductList/Header/Header'
import ProductListCards from 'components/ProductList/ProductListCards/ProductListCards'
import Skeleton from 'components/ProductList/Skeleton/Skeleton'
import { ProductListLayout, ResponseStatus } from 'frontend/enums'
import { getUserRecommendations } from 'frontend/lib/Api'
import { BlockTracking, RecommendationResult } from 'frontend/types'
import { getCookie, logError } from 'frontend/utils/helpers'
import { useEffect, useState } from 'react'
import ProductList from '../ProductList/ProductList'

interface Props {
  title: string | null
  blockTracking: BlockTracking
  endpoint: string
}

const Recommendations = ({
  title = 'Tips för dig',
  blockTracking,
  endpoint,
}: Props) => {
  const [recommendations, setRecommendations] = useState<
    RecommendationResult['results']
  >([])
  const [recommendationsId, setRecommendationsId] = useState('')
  const [fallbackUsed, setFallbackUsed] = useState(true)
  const [status, setStatus] = useState(ResponseStatus.PENDING)
  const [layout, setLayout] = useState(ProductListLayout.SHOW_LESS)

  useEffect(() => {
    const getRecommendations = async () => {
      const userId = getCookie('_pc_c')
      try {
        const response = await getUserRecommendations(userId ?? '', endpoint)
        setRecommendations(response.results)
        setRecommendationsId(response.id)
        setFallbackUsed(response.fallbackUsed)
        setStatus(ResponseStatus.RESOLVED)
        if (response.results.length === 0) {
          logError(new Error('Received empty recommendations'))
        }
      } catch (error) {
        setStatus(ResponseStatus.REJECTED)
        logError(error as Error)
      }
    }

    getRecommendations()
  }, [])

  if (status === ResponseStatus.REJECTED) return null

  if (recommendations.length === 0) return null

  return (
    <ProductList
      data-testid="Recommendations"
      data-trk-rec-list-id={recommendationsId}
      data-trk-rec-list-fallback={fallbackUsed}
    >
      <Header title={title}>
        <ProductListButtonContainer>
          <ProductListToggleButton
            numProducts={recommendations.length}
            layout={layout}
            setLayout={setLayout}
          />
        </ProductListButtonContainer>
      </Header>
      {status === ResponseStatus.RESOLVED && (
        <ProductListCards
          showAllCards={layout === ProductListLayout.SHOW_MORE}
          headingLevel={3}
          cardLayout={EdithCardLayout.DEFAULT}
          products={
            layout === ProductListLayout.SHOW_LESS
              ? recommendations.slice(0, 5)
              : recommendations
          }
          blockTracking={blockTracking}
          data-trk-rec-list-id={recommendationsId}
        />
      )}
      {status === ResponseStatus.PENDING && <Skeleton />}
    </ProductList>
  )
}

export default Recommendations
