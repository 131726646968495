import ArrowRight from 'assets/icons/arrow-right-white.svg'
import queryString from 'query-string'
import styles from './Header.module.css'
import { SearchParams } from 'backend/services/v1/urApiService.types'
import SpaLink from 'components/SpaLink/SpaLink'

const Header = ({
  children,
  searchQuery,
  title,
}: {
  children?: React.ReactNode
  title: string | null
  searchQuery?: SearchParams
}) => {
  const searchParams = queryString.stringify(
    {
      ...searchQuery,
      header: title,
    },
    { arrayFormat: 'bracket' },
  )

  return (
    <header className={styles.titleWrap}>
      <h2 className={styles.title} data-testid="product-list-title">
        {searchQuery ? (
          <SpaLink
            className={styles.link}
            data-trk-show-more={title}
            href={`/sok?${searchParams}`}
          >
            <span>
              {title}
              <ArrowRight
                aria-hidden
                className={styles.arrowRight}
                focusable="false"
              />
            </span>
          </SpaLink>
        ) : (
          title
        )}
      </h2>
      {children}
    </header>
  )
}

export default Header
