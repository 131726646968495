import { ProductListLayout } from 'frontend/enums'
import styles from './ProductListToggleButton.module.css'

const DISPLAY_BUTTON_CARD_LIMIT = 5

const ProductListToggleButton = ({
  numProducts,
  layout,
  setLayout,
}: {
  numProducts?: number
  layout: ProductListLayout
  setLayout: (layoutType: ProductListLayout) => void
}) => {
  const displayShowMoreButton =
    numProducts && numProducts > DISPLAY_BUTTON_CARD_LIMIT

  const isListActive = layout === ProductListLayout.SHOW_LESS
  const toggleGrid = () =>
    isListActive
      ? setLayout(ProductListLayout.SHOW_MORE)
      : setLayout(ProductListLayout.SHOW_LESS)

  return (
    <>
      {displayShowMoreButton && (
        <button
          className={styles.showMoreButton}
          data-trk-list-type={ProductListLayout.SHOW_MORE}
          onClick={() => toggleGrid()}
          type="button"
          title="Visa som lista över flera rader."
        >
          <span className={styles.showMoreText}>
            {isListActive ? 'Visa fler' : 'Visa färre'}
          </span>
        </button>
      )}
    </>
  )
}

export default ProductListToggleButton
