import { EdithCardLayout } from 'backend/services/v1/edithService.types'
import ProductListButtonContainer from 'components/ProductList/Buttons/ProductListButtonContainer'
import ProductListRemoveButton from 'components/ProductList/Buttons/ProductListRemoveButton'
import ProductListToggleButton from 'components/ProductList/Buttons/ProductListToggleButton'
import Header from 'components/ProductList/Header/Header'
import ProductList from 'components/ProductList/ProductList'
import ProductListCards from 'components/ProductList/ProductListCards/ProductListCards'
import Skeleton from 'components/ProductList/Skeleton/Skeleton'
import { ProductListLayout } from 'frontend/enums'
import { BlockTracking, Program } from 'frontend/types'
import { useEffect, useState } from 'react'
import { ProgressTracker } from '../UrPlayer/plugins/progressTracker/progressTracker'
import { fetchData, getContinuePlayingProgramIds } from './helpers'

interface ContinuePlayingProps {
  title?: string
  blockTracking: BlockTracking
  isChildPage?: boolean
}
export interface ContinuePlayingProgram extends Program {
  isNextEpisode: boolean
}

const ContinuePlaying = ({
  title = 'Fortsätt spela',
  blockTracking,
  isChildPage,
}: ContinuePlayingProps) => {
  const [products, setProducts] = useState<ContinuePlayingProgram[]>([])
  const [noResultsReceived, setNoResultsReceived] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [localStorageItems, setLocalStorageItems] = useState(
    ProgressTracker.getSavedData,
  )
  const [removeCardDraft, setRemoveCardDraft] = useState<number[]>([])
  const [numPrograms, setNumPrograms] = useState<number | undefined>()
  const [layout, setLayout] = useState(ProductListLayout.SHOW_LESS)

  useEffect(() => {
    const init = async () => {
      const results = await fetchData(localStorageItems, isChildPage)

      const continuePlayingPrograms = results?.map(program => {
        const playedPosition = localStorageItems?.programs[program.id]?.position

        return {
          ...program,
          isNextEpisode: playedPosition === 0,
        }
      }) as ContinuePlayingProgram[]

      if (results && results.length > 0) {
        setProducts(continuePlayingPrograms)
      } else {
        setNoResultsReceived(true)
      }

      setNumPrograms(
        getContinuePlayingProgramIds(localStorageItems?.programs).length,
      )
    }
    init()
  }, [])

  const handleStartEditing = () => setIsEditing(true)

  const handleEndEditing = () => {
    const nextProducts = products.filter(p => !removeCardDraft.includes(p.id))
    setProducts(nextProducts)

    ProgressTracker.trimProgramIds(nextProducts.map(p => p.id))

    setIsEditing(false)
    setRemoveCardDraft([])
    setLocalStorageItems(ProgressTracker.getSavedData())

    setNumPrograms(nextProducts.length)
  }

  if (!numPrograms || noResultsReceived) {
    return null
  }

  return (
    <div data-testid="ContinuePlaying">
      <ProductList>
        <Header title={title}>
          <ProductListButtonContainer>
            <ProductListRemoveButton
              isEditing={isEditing}
              onClick={isEditing ? handleEndEditing : handleStartEditing}
            />
            <ProductListToggleButton
              numProducts={products.length}
              layout={layout}
              setLayout={setLayout}
            />
          </ProductListButtonContainer>
        </Header>
        {products.length > 0 ? (
          <ProductListCards
            showAllCards={layout === ProductListLayout.SHOW_MORE}
            headingLevel={3}
            products={
              layout === ProductListLayout.SHOW_LESS
                ? products.slice(0, 5)
                : products
            }
            isEditing={isEditing}
            removeCardDraft={removeCardDraft}
            setRemoveCardDraft={setRemoveCardDraft}
            blockTracking={blockTracking}
            cardLayout={EdithCardLayout.DEFAULT}
          />
        ) : (
          <Skeleton />
        )}
      </ProductList>
    </div>
  )
}

export default ContinuePlaying
